import { Drawer, Form, Spin, Switch } from "antd";
import { DeleteZoneIcon } from "components/common/Icons/Icons";
import {
  AlertModal,
  ChangeStatusModal,
  DeleteModal,
} from "components/common/modals/modals";
import React, { useState } from "react";
import useWindowSize from "utils/useWindowSize";
import axios from "axios";
import { useEffect, useContext } from "react";
import { capitalize } from "utils/Capitalize";
import { LoadingOutlined } from "@ant-design/icons";
import { AppContext } from "app/AppContext";
import { STATUS } from "constants/types/types";

const AccionesPorteriasDrawer = (props) => {
  const { windowWidth } = useWindowSize();
  const [porterias, setPorterias] = useState([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [titleModal, setTitleModal] = useState();
  const [messageModal, setMessageModal] = useState(null);
  const [zoneToRemove, setZoneToRemove] = useState(null);
  const [statusChangePayload, setStatusChangePayload] = useState(null);
  const [isRecordDeleting, setIsRecordDeleting] = useState(false);
  const [isStatusChanging, setIsStatusChanging] = useState(false);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [alertTitle, setAlertTitle] = useState("");
  const [needsConfirm, setNeedsConfirm] = useState(false);
  const { rolData } = useContext(AppContext)

  useEffect(() => {
    if (props.visible) {
      getPorteriasBySpecieId();
    }
    console.log('rolData', rolData.id);
  }, [props]);

  const activatePorteria = async (porteria) => {
    setIsStatusChanging(true);
    setStatusChangePayload({
      specie_id: props.specie_id,
      porterias: [
        {
          porteria_id: parseInt(porteria.id),
          user_id: rolData.id,
          status_id: STATUS.ACTIVE,
        },
      ],
    });
    setTitleModal("Activar porteria");
    setMessageModal(`¿Estás seguro/a que deseas activar la porteria?`);
    setNeedsConfirm(false);
    setIsStatusChanging(false);
    setShowStatusModal(true);
  };

  const deactivatePorteria = async (porteria) => {
    setIsStatusChanging(true);
    setTitleModal("Desactivar porteria");
    setStatusChangePayload({
      specie_id: props.specie_id,
      porterias: [
        {
          porteria_id: parseInt(porteria.id),
          user_id: rolData.id,
          status_id: STATUS.INACTIVE,
        },
      ],
    });
    setMessageModal(
      `¿Estás seguro/a que deseas desactivar la porteria ${porteria.name}?`
    );
    setNeedsConfirm(false);
    setIsStatusChanging(false);
    setShowStatusModal(true);
  };

  const deletePorteria = async (porteria) => {
    setZoneToRemove(porteria);
    setTitleModal("Eliminar porteria");
    setIsRecordDeleting(true);

    setMessageModal(`¿Deseas eliminar la porteria?.`);

    setIsRecordDeleting(false);
    setShowConfirmModal(true);
  };

  const getPorteriasBySpecieId = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL_PORTERIAS}/porterias/more-options/find-all-porterias?specie_id=${props.specie_id}`
      );

      const tempPorterias = response.data.filter((porteria) => {
        return parseInt(porteria.status_id) !== 6;
      });
      const sortedTempPorterias = tempPorterias.sort((a, b) => {
        if (a.status_id === b.status_id) {
          return a.name.localeCompare(b.name);
        }
        return a.status_id === 1 ? -1 : 1;
      });
      setPorterias(sortedTempPorterias);
    } catch (error) {
      console.error(error);
    }
  };

  const closeConfirmModal = () => {
    setShowConfirmModal(false);
    setIsRecordDeleting(false);
    setIsStatusChanging(false);
    setShowStatusModal(false);
  };

  const onConfirmChangeStatus = async () => {
    setIsStatusChanging(true);
    await axios
      .post(
        `${process.env.REACT_APP_BASE_URL_PORTERIAS}/porterias/more-options/change-status`,
        statusChangePayload
      )
      .then((response) => {
        if (parseInt(response.data[0].status_id) === 1) {
          setAlertMessage("La porteria ha sido activada correctamente");
          setAlertTitle("Porteria activada!");
          setAlertType("success");
        } else {
          setAlertMessage("La porteria ha sido desactivada correctamente");
          setAlertTitle("Porteria desactivada!");
          setAlertType("success");
        }
      })
      .catch((err) => {
        console.error(err);
        setAlertMessage("Ocurrió un error al cambiar el estado de la porteria");
        setAlertTitle("¡Error!");
        setAlertType("error");
      })
      .finally(() => {
        setIsStatusChanging(false);
        getPorteriasBySpecieId();
        closeConfirmModal();
        props.onClose();
        setShowAlertModal(true);
      });
  };

  const onConfirmDelete = async () => {
    setIsRecordDeleting(true);
    await axios
      .post(
        `${process.env.REACT_APP_BASE_URL_PORTERIAS}/porterias/more-options/delete-status`,
        {
          specie_id: props.specie_id,
          porteria_id: parseInt(zoneToRemove.id),
          user_id: rolData.id,
        }
      )
      .then((response) => {
        setAlertMessage("Porteria eliminada correctamente");
        setAlertTitle("¡Porteria eliminada!");
        setAlertType("success");
      })
      .catch((err) => {
        console.error(err);
        setAlertMessage("Ocurrió un error al eliminar la porteria");
        setAlertTitle("¡Error!");
        setAlertType("error");
      })
      .finally(() => {
        closeConfirmModal();
        props.onClose();
        setShowAlertModal(true);
      });
  };

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 16,
      }}
      spin
    />
  );

  return (
    <div className="">
      <Drawer
        title="Acciones en las porterias"
        placement="right"
        onClose={props.onClose}
        visible={props.visible}
        width={windowWidth > 768 ? 600 : "90%"}
        bodyStyle={{ padding: windowWidth > 1024 ? "24px" : "12px" }}
      >
        <div className="filter-criteria">
          <Form>
            {porterias.length
              ? porterias.map((porteria) => {
                  return (
                    <div className="row_item_piramides" key={porteria.id}>
                      <div className="item_piramides">
                        {capitalize(porteria.name)}
                      </div>
                      <Form.Item>
                        <div className="item_status">
                          <Switch
                            loading={isStatusChanging}
                            checkedChildren="Sí"
                            unCheckedChildren="No"
                            defaultChecked={parseInt(porteria.status_id) === 1}
                            checked={parseInt(porteria.status_id) === 1}
                            onChange={
                              parseInt(porteria.status_id) === 1
                                ? () => deactivatePorteria(porteria)
                                : () => activatePorteria(porteria)
                            }
                          />
                          <span>Activado</span>
                        </div>
                      </Form.Item>
                      <div className="item_delete">
                        {isRecordDeleting ? (
                          <Spin indicator={antIcon} />
                        ) : (
                          <>
                            <span onClick={() => deletePorteria(porteria)}>
                              <DeleteZoneIcon />
                              <span>Eliminar</span>
                            </span>
                          </>
                        )}
                      </div>
                    </div>
                  );
                })
              : null}
          </Form>
        </div>
      </Drawer>
      <DeleteModal
        title={titleModal}
        onClose={closeConfirmModal}
        messageModal={messageModal}
        show={showConfirmModal}
        specie_id={props.specie_id}
        onConfirm={onConfirmDelete}
        isLoading={isRecordDeleting}
        needsConfirm={needsConfirm}
      ></DeleteModal>
      <ChangeStatusModal
        title={titleModal}
        onClose={closeConfirmModal}
        messageModal={messageModal}
        show={showStatusModal}
        specie_id={props.specie_id}
        onConfirm={onConfirmChangeStatus}
        isLoading={isStatusChanging}
        needsConfirm={needsConfirm}
      ></ChangeStatusModal>
      <AlertModal
        onClose={() => setShowAlertModal(false)}
        show={showAlertModal}
        type={alertType}
        title={alertTitle}
      >
        {alertMessage}
      </AlertModal>
    </div>
  );
};

export default AccionesPorteriasDrawer;
