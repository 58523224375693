import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Steps, Col, Form, Row, message } from "antd";
import { PrimaryButton, SecondaryButton } from "components/common/Buttons";
import axios from "axios";
import CodigoVerificacionExterno from "./CodigoVerificaciónExterno";
import FormNuevaSolicitudExterno from "./FormNuevaSolicitudExterno";
import DeclaracionJuradaIngresoExterno from "./DeclaracionJuradaIngresoExterno";
import DeclaracionVisitasExterno from "./DeclaracionVisitasExterno";

const tipoVisitaDeclaracion = {
  Externa: 1,
  Interna: 2,
};

const personalId = {
  "Visitas AS": 1,
  Visitas: 5,
};

const NuevaSolicitudExterno = () => {
  const [current, setCurrent] = useState(0);
  const [searchParams] = useSearchParams();
  const [selectedSpecie, setSelectedSpecie] = useState("001");
  const [specie, setSpecie] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDates, setSelectedDates] = useState("");
  const [cerdoData, setCerdoData] = useState(null);
  const [pavoData, setPavoData] = useState(null);
  const [polloData, setPolloData] = useState(null);
  const [alimentosData, setAlimentosData] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState(1);
  const [agreeCheck, setAgreeCheck] = useState(false);
  const [messageAgreeError, setMessageAgreeError] = useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [showCodigo, setShowCodigo] = useState(false);
  const [userId, setUserId] = useState(0);
  const [isValidCodigo, setIsValidCodigo] = useState(false);
  const [modalStatus, setModalStatus] = useState(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [rutColaborator, setRutColaborator] = useState("");
  const tipoVisita = parseInt(searchParams.get("tipoVisita")) || 1;
  const [valueSelectPlants, setValueSelectPlants] = useState([]);
  const [allPlants, setAllPlants] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [sendCodeVerification, setSendCodeVerification] = useState(false);
  const [showField, setShowField] = useState(false);
  const [radioValue, setRadioValue] = useState("");
  const [treeData, setTreeData] = useState([]);
  const [blockRut, setBlockRut] = useState(false);

  const messageDisplay = ({ type = "info", msgContent = "", duration = 3 }) => {
    messageApi.open({
      type,
      content: msgContent,
      duration,
    });
  };

  const { token } = useParams();

  const onChangeManualStep = (value) => {
    setCurrent(value);
  };

  useEffect(() => {
    axios.interceptors.request.use(
      (config) => {
        config.headers = {
          ...config.headers,
          ...(token && {
            "guess-access-token": JSON.stringify({
              token: token,
            }),
          }),
        };
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }, [token]);

  const handleFinish = async () => {
    setIsLoadingSubmit(true);
    const values = form.getFieldValue();

    const url =
      process.env.REACT_APP_BASE_URL_REQUESTS + "/declaration-external";

    let permission_access = [];

    cerdoData && permission_access.push(cerdoData);
    pavoData && permission_access.push(pavoData);
    polloData && permission_access.push(polloData);
    alimentosData && permission_access.push(alimentosData);

    const hasPermissionData =
      cerdoData || pavoData || polloData || alimentosData;

    if (!permission_access.length || !hasPermissionData) {
      messageDisplay({
        type: "error",
        msgContent: `Debe seleccionar al menos un permiso de acceso, regrese al primer paso.`,
      });
      return;
    }

    let payload = {
      identification_type: values?.identification_type,
      rut: values.rut,
      passport: values?.passport,
      email: values?.email,
      name: values?.name,
      lastname: values?.lastname,
      ocupation: values?.position_id,
      nationality_id: values?.nationality_id,
      responsible: values?.responsible,
      reason_id: 25,
      reason_name: values?.reason_name,
      cloth_size: values?.cloth_size,
      shoe_size: values?.shoe_size,
      work_with_animals: values?.work_with_animals,
      country_id: values?.country,
      city: values?.city,
      company_name: values?.company_name,
      phone_number: values?.phone_number,
      prefix: Number(values?.prefix),
      inicioTermino: values?.inicioTermino,
      authorization_number: values?.authorization_number,
      permission_access: permission_access,
      type_personal_id:
        tipoVisita === tipoVisitaDeclaracion.Externa
          ? personalId.Visitas
          : personalId["Visitas AS"],
      tipoVisita,
    };

    await axios
      .post(url, payload)
      .then(() => {
        setIsLoadingSubmit(false);
        // window.open(`${window.location.href}/ConfirmacionFirmaDeclaracion`);
        navigate("/declaraciones/visita-externa/ConfirmacionFirmaDeclaracion");
      })
      .catch(() => {
        setIsLoadingSubmit(false);
        // window.open(`${window.location.href}/ErrorFirmaDeclaracion`);
        navigate("/declaraciones/visita-externa/ErrorFirmaDeclaracion");
      });
  };

  const getSpecies = async () => {
    setIsLoading(true);
    const urlSpecie =
      process.env.REACT_APP_BASE_URL_REQUESTS + "/data-master-external/species";
    axios
      .get(urlSpecie)
      .then((response) => {
        const order = [...response.data].sort((a, b) => a.code - b.code);
        console.log("order", order);
        setSpecie(order);
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getSpecies();
  }, []);

  const steps = [
    {
      title: "Paso 1",
      key: 1,
      description: "Información de la visita",
      content: (
        <FormNuevaSolicitudExterno
          form={form}
          setSelectedSpecie={setSelectedSpecie}
          selectedSpecie={selectedSpecie}
          specie={specie}
          isLoading={isLoading}
          setSelectedDates={setSelectedDates}
          selectedDates={selectedDates}
          setCerdoData={setCerdoData}
          setPavoData={setPavoData}
          setPolloData={setPolloData}
          setAlimentosData={setAlimentosData}
          handleFinish={handleFinish}
          setModalStatus={setModalStatus}
          modalStatus={modalStatus}
          setValueSelectPlants={setValueSelectPlants}
          valueSelectPlants={valueSelectPlants}
          allPlants={allPlants}
          setAllPlants={setAllPlants}
          radioValue={radioValue}
          setRadioValue={setRadioValue}
          treeData={treeData}
          setTreeData={setTreeData}
        />
      ),
      fields: [
        "inicioTermino",
        "responsible",
        "reason",
        selectedSpecie === "004" ? "" : "radio-buttons",
        "sectores",
        "list-data",
        selectedSpecie === "004" ? "plants" : "",
      ],
    },
    {
      title: "Paso 2",
      key: 2,
      description: "Información personal de la visita",
      content: (
        <DeclaracionJuradaIngresoExterno
          form={form}
          setUserId={setUserId}
          tipoVisita={tipoVisita}
          setRutColaborator={setRutColaborator}
          showField={showField}
          setShowField={setShowField}
          blockRut={blockRut}
          setBlockRut={setBlockRut}
        />
      ),
      fields: [
        "rut",
        "passport",
        "email",
        "name",
        "lastname",
        "position_id",
        "nationality_id",
        "company_name",
        "phone_number",
        "country",
        "city",
        "work_with_animals",
        "shoe_size",
        "cloth_size",
        "prefix",
      ],
    },
    {
      title: "Paso 3",
      key: 3,
      description: "Declaración",
      content: (
        <DeclaracionVisitasExterno
          form={form}
          selectedSpecie={selectedSpecie}
          selectedLanguage={selectedLanguage}
          setSelectedLanguage={setSelectedLanguage}
          agreeCheck={agreeCheck}
          setAgreeCheck={setAgreeCheck}
          messageAgreeError={messageAgreeError}
          setMessageAgreeError={setMessageAgreeError}
          handleFinish={handleFinish}
          setValueSelectPlants={setValueSelectPlants}
          valueSelectPlants={valueSelectPlants}
          allPlants={allPlants}
          setAllPlants={setAllPlants}
        />
      ),
    },
    {
      title: "Paso 4",
      key: 4,
      description: "Firma Digital",
      content: (
        <CodigoVerificacionExterno
          form={form}
          showCodigo={showCodigo}
          setShowCodigo={setShowCodigo}
          userId={userId}
          setIsValidCodigo={setIsValidCodigo}
          isValidCodigo={isValidCodigo}
          handleFinish={handleFinish}
          setSendCodeVerification={setSendCodeVerification}
        />
      ),
    },
  ];

  const next = async () => {
    try {
      await form.validateFields(steps[current].fields);
      const hasPermissionData =
        cerdoData || pavoData || polloData || alimentosData;
      if (!hasPermissionData) {
        messageDisplay({
          type: "error",
          msgContent: `Debe seleccionar al menos un permiso de acceso.`,
        });
        return;
      }
      if (current === 2) {
        setBlockRut(true);
        if (agreeCheck) {
          setMessageAgreeError(false);
          setCurrent((prev) => prev + 1);
        } else {
          setMessageAgreeError(true);
        }
      } else {
        setCurrent((prev) => prev + 1);
      }
    } catch {}
  };

  const prev = () => {
    setCurrent((prev) => prev - 1);
  };

  return (
    <>
      {contextHolder}
      <div className="crear-declaracion__container">
        <Col md={20}>
          <Steps current={current} items={steps} />
        </Col>
      </div>
      <div className="steps-content">{steps[current].content}</div>

      <Row justify="center" vgutter={8} className="crear-declaracion__botonera">
        {current === 0 && (
          <SecondaryButton
            width={200}
            onButtonClick={() => {
              setModalStatus(true);
            }}
          >
            Cancelar
          </SecondaryButton>
        )}
        {current > 0 && (
          <SecondaryButton
            width={200}
            disabled={sendCodeVerification}
            onButtonClick={() => prev()}
          >
            Volver
          </SecondaryButton>
        )}
        {current === steps.length - 1 && (
          <PrimaryButton
            onButtonClick={() => handleFinish()}
            width={200}
            disabled={!isValidCodigo}
            isLoading={isLoadingSubmit}
          >
            Finalizar
          </PrimaryButton>
        )}
        {current < steps.length - 1 && (
          <PrimaryButton type="button" width={200} onButtonClick={() => next()}>
            Siguiente
          </PrimaryButton>
        )}
      </Row>
    </>
  );
};
export default NuevaSolicitudExterno;
